.layout-container{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    
    .sidebar-container{
        position: fixed;
        top: 50px;
       
        left: 0;
        bottom: 0;
        background: #29258C;
        height: 100vh;
        
        color: white;
        z-index: 100;
        overflow-y: auto !important;
       
    }
    .home-container{
      
        position: absolute;
        right: 0;
        top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        min-height: 100vh;
      

    }
}