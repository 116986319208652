*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  overflow-x: hidden;
}
/* .card, .btn, .modal, input, select{
  border-radius: 0 !important;
} */

