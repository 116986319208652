.sidebar-items{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transition: 250ms ease-out;
    text-transform: capitalize;
    padding: .5rem .3rem ;
    cursor: pointer;
    &:hover{
        transition: 250ms ease-in;
        background-color: #070c49;
        border-left: 10px solid #29258C;

    }
    i{
        font-size: 45px;
    }
    
}